import Vue from 'vue'
import Router from 'vue-router'
import auth from './middleware/auth'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [
    {
      path: '/Login',
      name: 'login',
      component: () => import('./views/Login.vue')
    },
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // ==========================================================================
        // Theme Routes
        // ==========================================================================
        {
          path: '/',
          name: 'dashbord',
          component: () => import('./views/Dashboard/Dashboard.vue'),
          meta: {
            middleware: [auth]
          }
        },
        {
          path: '/Month',
          name: 'month',
          component: () => import('./views/Dashboard/Month.vue'),
          meta: {

            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Dashboard' },
              { title: 'Month ', active: true }
            ],
            pageTitle: 'Month',
            rule: 'editor',
            middleware: [auth]
          }
        },
        {
          path: '/Month23h',
          name: 'month23h',
          component: () => import('./views/Dashboard/Month23h.vue'),
          meta: {

            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Dashboard' },
              { title: 'Month23h ', active: true }
            ],
            pageTitle: 'Month23h',
            rule: 'editor',
            middleware: [auth]
          }
        },
        {
          path: '/MonthDiff',
          name: 'monthDiff',
          component: () => import('./views/Dashboard/MonthDiff.vue'),
          meta: {

            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Dashboard' },
              { title: 'MonthDiff ', active: true }
            ],
            pageTitle: 'Month',
            rule: 'editor',
            middleware: [auth]
          }
        },
        {
          path: '/Winloss',
          name: 'winloss',
          component: () => import('./views/Dashboard/Winloss.vue'),
          meta: {

            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Dashboard' },
              { title: 'Winloss ', active: true }
            ],
            pageTitle: 'Winloss',
            rule: 'editor',
            middleware: [auth]
          }
        },
        //===============================================================
        // member
        //===============================================================
        {
          path: '/member',
          name: 'member',
          component: () => import('./views/Member/Member.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Member', active: true }
            ],
            pageTitle: 'Member',
            rule: 'editor'
          }
        },
        {
          path: 'member/:username',
          name: 'listmember',
          component: () => import('./views/Member/UserInfo.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Member', url: '/member' },
              { title: 'Member List', active: true }
            ],
            pageTitle: 'Member List',
            rule: 'editor'
          }
        },
        {
          path: 'topupwd-history/:username',
          name: 'topupwd-history',
          component: () => import('./views/Member/TopupWithdrawHistory.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Member', url: '/member' },
              { title: 'Member List', active: true }
            ],
            pageTitle: 'Member List',
            rule: 'editor'
          }
        },
        {
          path: '/membersearch',
          name: 'member',
          component: () => import('./views/Member/MemberSearch.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'membersearch', active: true }
            ],
            pageTitle: 'membersearch',
            rule: 'editor'
          }
        },
        //===============================================================
        // End member
        //===============================================================
        {
          path: '/agent',
          name: 'agent',
          component: () => import('./views/Agent/Agent.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Agent', active: true }
            ],
            pageTitle: 'Agent',
            rule: 'editor'
          }
        },
        {
          path: '/agentminus',
          name: 'agentminus',
          component: () => import('./views/Agentminus.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Agentminus', active: true }
            ],
            pageTitle: 'Agentminus',
            rule: 'editor'
          }
        },
        {
          path: '/Topup',
          name: 'topup',
          component: () => import('./views/Topup/Topup.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Topup List', active: true }
            ],
            pageTitle: 'Topup List',
            rule: 'editor'
          }
        },
        {
          path: '/creditfreelist',
          name: 'creditfreelist',
          component: () => import('./views/Creditfree/Creditfreelist.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Creditfree Wait', active: true }
            ],
            pageTitle: 'Creditfree Wait',
            rule: 'editor'
          }
        },
        {
          path: '/creditfreeinfo',
          name: 'creditfreeinfo',
          component: () => import('./views/Creditfree/Creditfreeinfo.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Creditfree info', active: true }
            ],
            pageTitle: 'Creditfree info',
            rule: 'editor'
          }
        },
        {
          path: '/creditfreewait',
          name: 'creditfreewait',
          component: () => import('./views/Creditfree/Creditfreewait.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Creditfree List', active: true }
            ],
            pageTitle: 'Creditfree List',
            rule: 'editor'
          }
        },
        //===============================================================
        // withdrow
        //===============================================================
        {
          path: '/Withdraw',
          name: 'withdraw',
          component: () => import('./views/Withdraw/Withdraw.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Withdraw' },
              { title: 'Withdraw', active: true }
            ],
            pageTitle: 'Withdraw',
            rule: 'editor'
          }
        },
        {
          path: '/withdrawwait/:id',
          name: 'withdrawwait',
          component: () => import('./views/Withdraw/WithdrawWait.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Withdraw' },
              { title: 'Withdraw Wait', active: true }
            ],
            pageTitle: 'Withdraw Wait',
            rule: 'editor'
          }
        },
        {
          path: '/WithdrawList',
          name: 'withdrawelist',
          component: () => import('./views/Withdraw/WithdrawList.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Withdraw' },
              { title: 'Withdraw List', active: true }
            ],
            pageTitle: 'Withdraw List',
            rule: 'editor'
          }
        },
        //===============================================================
        // End withdrow
        //===============================================================
        // ========================Start Setting bank Show ====================
        {
          path: '/SettingBank',
          name: 'settingBank',
          component: () => import('./views/SettingBank/SettingBank.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'SettingBank' },
              { title: 'SettingBank ', active: true }
            ],
            pageTitle: 'SettingBank',
            rule: 'editor'
          }
        },
        {
          path: '/SettingBank/EditCustomBank/:id',
          name: 'settingdepositedit',
          component: () => import('./views/SettingBank/EditCustomBank.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'SettingBank', url: '/SettingBank' },
              { title: 'EditCustomBank', active: true }
            ],
            pageTitle: 'แก้ไขรูปแบบการโชว์ธนาคาร',
            rule: 'editor'
          }
        },
        // ======================== End Setting bank Show ====================
        //===============================================================
        // Deposit
        //===============================================================
        ///// Truewallet List ///////////////////////////////////////////
        {
          path: '/Wallet',
          name: 'wallet',
          component: () => import('./views/Wallet/Wallet.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Deposit' },
              { title: 'Truewallet ', active: true }
            ],
            pageTitle: 'Truewallet',
            rule: 'editor'
          }
        },
        {
          path: 'Wallet/List/:id',
          name: 'list',
          component: () => import('./views/Wallet/List.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Deposit' },
              { title: 'Truewallet', url: '/Wallet' },
              { title: 'Truewallet List', active: true }
            ],
            pageTitle: 'Truewallet List',
            rule: 'editor'
          }
        },
        {
          path: 'Wallet/ListSms/:id',
          name: 'listsms',
          component: () => import('./views/Wallet/ListSms.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Deposit' },
              { title: 'Truewallet', url: '/Wallet' },
              { title: 'Truewallet SMS', active: true }
            ],
            pageTitle: 'Truewallet SMS',
            rule: 'editor'
          }
        },
        ///// End Truewallet List //////////////////////////////////////

        ///// Bank List ////////////////////////////////////////////////
        {
          path: '/Bank',
          name: 'bank',
          component: () => import('./views/Bank/Bank.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Deposit' },
              { title: 'Bank ', active: true }
            ],
            pageTitle: 'Bank',
            rule: 'editor'
          }
        },
        {
          path: 'Bank/banklist/:bank/:id/:bankfor/',
          name: 'list',
          component: () => import('./views/Bank/banklist.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Bank', url: '/Bank' },
              { title: 'Bank List', active: true }
            ],
            pageTitle: 'Bank List',
            rule: 'editor'
          }
        },
        {
          path: 'Bank/banklistout/:bank/:id/:bankfor/',
          name: 'list',
          component: () => import('./views/Bank/banklistout.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Bank', url: '/Bank' },
              { title: 'Bank List', active: true }
            ],
            pageTitle: 'Bank List',
            rule: 'editor'
          }
        },
        {
          path: 'Bank/banklisterror/:bank',
          name: 'list',
          component: () => import('./views/Bank/banklisterror.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Bank', url: '/Bank' },
              { title: 'Bank List ERROR', active: true }
            ],
            pageTitle: 'Bank List ERROR',
            rule: 'editor'
          }
        },
        ///// End Bank List ///////////////////////////////////////////
        /*-------------VIZPAY----------------*/
        {
          path: '/Payment',
          name: 'payment',
          component: () => import('./views/Payment/Payment.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Transaction' },
              { title: 'Payment Deposit', active: true }
            ],
            pageTitle: 'Payment Deposit',
            rule: 'editor'
          }
        },
        {
          path: '/payment-trans',
          name: 'payment',
          component: () => import('./views/Payment/VizPayTrans.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Transaction' },
              { title: 'Payment Transaction', active: true }
            ],
            pageTitle: 'Payment Transaction',
            rule: 'editor'
          }
        },
        /*-------------HENGPAY----------------*/
        {
          path: '/hengpay',
          name: 'hengpay',
          component: () => import('./views/Hengpay/HengpayTransaction.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Transaction' },
              { title: 'Heng Transaction', active: true }
            ],
            pageTitle: 'Heng Transaction',
            rule: 'editor'
          }
        },
        /*-------------AKPAY----------------*/
        {
          path: '/akpay',
          name: 'akpay',
          component: () => import('./views/AKPay/AKPayTransaction.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Transaction' },
              { title: 'AK Transaction', active: true }
            ],
            pageTitle: 'AK Transaction',
            rule: 'editor'
          }
        },
        {
          path: '/akpay-generate',
          name: 'akpay-generate',
          component: () => import('./views/AKPay/AKPayGenerateQR.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Transaction' },
              { title: 'AK Generate Transaction', active: true }
            ],
            pageTitle: 'AK Generate Transaction',
            rule: 'editor'
          }
        },
        /*-------------OMEGAPAY----------------*/
        {
          path: '/omegapay',
          name: 'omegapay',
          component: () => import('./views/OmegaPay/OmegaPayTransaction.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Transaction' },
              { title: 'Omega Transaction', active: true }
            ],
            pageTitle: 'Omega Transaction',
            rule: 'editor'
          }
        },
        /*-------------POMPAY----------------*/
        {
          path: '/pompay',
          name: 'pompay',
          component: () => import('./views/PomPay/PomPayTransaction.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Transaction' },
              { title: 'Pompay Transaction', active: true }
            ],
            pageTitle: 'Pompay Transaction',
            rule: 'editor'
          }
        },
        {
          path: '/pompay-generate',
          name: 'pompay-generate',
          component: () => import('./views/PomPay/PomPayGenerateQR.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Transaction' },
              { title: 'Pompay Generate QR', active: true }
            ],
            pageTitle: 'Pompay Generate QR',
            rule: 'editor'
          }
        },
        {
          path: '/pompay-ckeck-slip',
          name: 'pompay-ckeck-slip',
          component: () => import('./views/PomPay/PomPayCheckSlip.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Transaction' },
              { title: 'Pompay Ckeck Slip', active: true }
            ],
            pageTitle: 'Pompay Ckeck Slip',
            rule: 'editor'
          }
        },
        /*-------------PAY2COIN----------------*/
        {
          path: '/pay2coin',
          name: 'pay2coin',
          component: () => import('./views/Pay2Coin/Pay2CoinTransaction.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Transaction' },
              { title: 'Pay2Coin Transaction', active: true }
            ],
            pageTitle: 'Pay2Coin Transaction',
            rule: 'editor'
          }
        },
        {
          path: '/pay2coin-dashboard',
          name: 'pay2coin-dashboard',
          component: () => import('./views/Pay2Coin/Pay2CoinDashboard.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Dashboard' },
              { title: 'Pay2Coin Dashboard', active: true }
            ],
            pageTitle: 'Pay2Coin Dashboard',
            rule: 'editor'
          }
        },
        /* --------------FPAYMENT------------- */
        {
          path: '/fpayment',
          name: 'payment',
          component: () => import('./views/Fpayment/Transaction.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Transaction' },
              { title: 'Fpayment Deposit', active: true }
            ],
            pageTitle: 'Fpayment Deposit',
            rule: 'editor'
          }
        },
        /* --------------7P------------- */
        {
          path: '/7p',
          name: 'payment',
          component: () => import('./views/7P/Transaction.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Transaction' },
              { title: '7P Deposit', active: true }
            ],
            pageTitle: '7P Deposit',
            rule: 'editor'
          }
        },
        /* --------------MTP------------- */
        {
          path: '/mtp',
          name: 'payment',
          component: () => import('./views/MTP/Transaction.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Transaction' },
              { title: 'MTP Deposit', active: true }
            ],
            pageTitle: 'MTP Deposit',
            rule: 'editor'
          }
        },
        //===============================================================
        // End Deposit
        //===============================================================
        //===============================================================
        // Setting
        //===============================================================
        {
          path: '/settingsystem',
          name: 'settingsystem',
          component: () => import('./views/Setting/SettingSystem.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Setting' },
              { title: 'System Settings', active: true }
            ],
            pageTitle: 'System Settings',
            rule: 'editor'
          }
        },
        {
          path: '/settingRateCrypto',
          name: 'settingRateCrypto',
          component: () => import('./views/Setting/SettingCrypto.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Setting' },
              { title: 'SettingRateCrypto', active: true }
            ],
            pageTitle: 'SettingRateCrypto',
            rule: 'editor'
          }
        },
        {
          path: '/SettingRanking',
          name: 'SettingRanking',
          component: () => import('./views/Setting/SettingCrypto.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Setting' },
              { title: 'SettingCrypto', active: true }
            ],
            pageTitle: 'SettingCrypto',
            rule: 'editor'
          }
        },
        {
          path: '/settingwithdraw',
          name: 'settingwithdraw',
          component: () => import('./views/Setting/SettingWithdraw.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Setting' },
              { title: 'Withdraw Settings', active: true }
            ],
            pageTitle: 'Withdraw Settings',
            rule: 'editor'
          }
        },
        {
          path: 'SettingPopup',
          name: 'settingpopup',
          component: () => import('../src/views/Setting/SettingPopup/index.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Setting' },
              { title: 'Popup Setting', active: true }
            ],
            pageTitle: 'Popup Setting',
            rule: 'editor'
          }
        },
        {
          path: '/Settingranking',
          name: 'ListRanking',
          component: () => import('./views/Setting/SettingRanking.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Setting' },
              { title: 'ListRanking', active: true }
            ],
            pageTitle: 'SettingRanking',
            rule: 'editor'
          }
        },
        {
          path: '/SettingMvp',
          name: 'ListRanking',
          component: () => import('./views/Setting/SettingMvp.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Setting' },
              { title: 'SettingMvp', active: true }
            ],
            pageTitle: 'SettingMvp',
            rule: 'editor'
          }
        },
        {
          path: 'settingStaff',
          name: 'settingstaff',
          component: () => import('./views/SettingStaff/SettingStaff.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Setting Staff', url: '/settingstaff' },
              { title: 'Staff Setting', active: true }
            ],
            pageTitle: 'Staff Setting',
            rule: 'editor'
          }
        },
        {
          path: 'SettingStaff/Edit/:id',
          name: 'settingstaffedit',
          component: () => import('./views/SettingStaff/SettingStaffEdit.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Setting Staff', url: '/settingstaff' },
              { title: 'Staff Setting Edit', active: true }
            ],
            pageTitle: 'Staff Setting Edit',
            rule: 'editor'
          }
        },
        ///////// Setting Wallet ///////////
        {
          path: '/SettingWalletDeposit',
          name: 'settingwwalletdeposit',
          component: () => import('./views/Setting/SettingWallet/WalletDeposit/SettingWalletDeposit.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Setting', url: '/SettingWalletDeposit' },
              { title: 'Deposit Setting', active: true }
            ],
            pageTitle: 'Deposit Setting',
            rule: 'editor'
          }
        },
        {
          path: '/SettingWalletWithdraw',
          name: 'settingwalletwithdraw',
          component: () => import('./views/Setting/SettingWallet/WalletWithdraw/SettingWalletWithdraw.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Setting', url: '/SettingWalletDeposit' },
              { title: 'Withdraw Setting', active: true }
            ],
            pageTitle: 'Withdraw Setting',
            rule: 'editor'
          }
        },
        {
          path: '/SettingTruewallet',
          name: 'settingtruewallet',
          component: () => import('./views/Setting/SettingWallet/WalletTruewallet/SettingWalletTruewallet.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Setting', url: '/SettingTruewallet' },
              { title: 'Truewallet Setting', active: true }
            ],
            pageTitle: 'Truewallet Setting',
            rule: 'editor'
          }
        },
        {
          path: '/SettingWalletDeposit/SettingWalletedit/:bank/:id',
          name: 'settingdepositedit',
          component: () => import('./views/Setting/SettingWallet/WalletDeposit/SettingWalletedit.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Setting', url: '/SettingWalletDeposit' },
              { title: 'Deposit Setting', url: '/SettingWalletDeposit' },
              { title: 'Edit Deposit', active: true }
            ],
            pageTitle: 'แก้ไขข้อมูลบัญชีฝาก',
            rule: 'editor'
          }
        },
        {
          path: '/SettingWalletWithdraw/EditWithdraw/:bank/:id',
          name: 'settingwithdrawedit',
          component: () => import('./views/Setting/SettingWallet/WalletWithdraw/EditWithdraw.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Setting', url: '/SettingWalletWithdraw' },
              { title: 'Withdraw Setting', url: '/SettingWalletWithdraw' },
              { title: 'Edit Withdraw', active: true }
            ],
            pageTitle: 'แก้ไขข้อมูลบัญชีถอน',
            rule: 'editor'
          }
        },
        {
          path: '/SettingWalletWithdraw/EditWithdrawKkp/:bank/:id',
          name: 'settingwithdraweditKkp',
          component: () => import('./views/Setting/SettingWallet/WalletWithdraw/EditWithdrawKkp.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Setting', url: '/SettingWalletWithdraw' },
              { title: 'Withdraw Setting', url: '/SettingWalletWithdraw' },
              { title: 'Edit Withdraw', active: true }
            ],
            pageTitle: 'แก้ไขข้อมูลบัญชีถอน',
            rule: 'editor'
          }
        },
        {
          path: '/settingwallettruewallet/editListtruewallet/:bank/:id',
          name: 'settingwithdrawedit',
          component: () => import('./views/Setting/SettingWallet/WalletTruewallet/EditListTruewallet.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Setting', url: '/SettingTruewallet' },
              { title: 'TrueWallet Setting', url: '/SettingTruewallet' },
              { title: 'Edit TrueWallet', active: true }
            ],
            pageTitle: 'แก้ไขข้อมูลบัญชีทรูวอลเล็ต',
            rule: 'editor'
          }
        },
        ///////// End Setting Wallet /////////
        //===============================================================
        // End Setting
        //===============================================================

        // Callcenter Start////////////////
        {
          path: '/Callcenter-list',
          name: 'callcenter',
          component: () => import('./views/Callcenter/List.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'List' },
              { title: 'Callcenter List', active: true }
            ],
            pageTitle: 'Callcenter List',
            rule: 'editor'
          }
        },
        {
          path: '/Callcenter-Confirm',
          name: 'callcenter',
          component: () => import('./views/Callcenter/Confirm.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Transaction' },
              { title: 'Callcenter Transaction', active: true }
            ],
            pageTitle: 'Callcenter Transaction',
            rule: 'editor'
          }
        },
        {
          path: '/Callcenter-Setting',
          name: 'callcenter',
          component: () => import('./views/Callcenter/Setting/List.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Transaction' },
              { title: 'Callcenter Transaction', active: true }
            ],
            pageTitle: 'Callcenter Transaction',
            rule: 'editor'
          }
        },
        {
          path: '/ReportPromotion',
          name: 'reportpromotion',
          component: () => import('./views/Report/ReportPromotion.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Report', url: '/reportpromotion' },
              { title: 'Report Bonus', active: true }
            ],
            pageTitle: 'Report รับโบนัส',
            rule: 'editor'
          }
        },
        {
          path: '/ReportAff',
          name: 'reportaff',
          component: () => import('./views/Report/ReportAff.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Report', url: '/ReportAff' },
              { title: 'Report Aff', active: true }
            ],
            pageTitle: 'Report แนะนำเพื่อน',
            rule: 'editor'
          }
        },
        {
          path: '/ReportTournamentPnl',
          name: 'reportaff',
          component: () => import('./views/Report/ReportTournamentPnl.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Report', url: '/ReportTournamentPnl' },
              { title: 'Report Tournament', active: true }
            ],
            pageTitle: 'Report เกาจิ้งเมืองไทย',
            rule: 'editor'
          }
        },
        {
          path: '/ReportTurn',
          name: 'reportturn',
          component: () => import('./views/Report/ReportTurn.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Report', url: '/ReportTurn' },
              { title: 'Report Return', active: true }
            ],
            pageTitle: 'Report คืนยอดเล่น',
            rule: 'editor'
          }
        },
        {
          path: '/ReportRegister',
          name: 'reportregister',
          component: () => import('./views/Report/ReportRegister.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Report', url: '/ReportRegister' },
              { title: 'Report Register', active: true }
            ],
            pageTitle: 'Report สมัครสมาชิก/เติมเงิน',
            rule: 'editor'
          }
        },
        {
          path: '/ReportMinigame',
          name: 'reportminigame',
          component: () => import('./views/Report/ReportMinigame.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Report', url: '/ReportMinigame' },
              { title: 'Report Minigame', active: true }
            ],
            pageTitle: 'Report เปิดไพ่ / กงล้อ',
            rule: 'editor'
          }
        },
        {
          path: '/ReportLog',
          name: 'reportlog',
          component: () => import('./views/Report/ReportLog.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Report', url: '/ReportLog' },
              { title: 'Report Log', active: true }
            ],
            pageTitle: 'Report ประวัติการใช้งาน',
            rule: 'editor'
          }
        },
        {
          path: '/ReportLogUsage',
          name: 'reportlogusage',
          component: () => import('./views/Report/ReportLogUsage.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Report', url: '/ReportLogUsage' },
              { title: 'Report Log', active: true }
            ],
            pageTitle: 'Report ประวัติการใช้งาน',
            rule: 'editor'
          }
        },
        {
          path: '/ReportActiveuser',
          name: 'reportactiveuser',
          component: () => import('./views/Report/ReportActiveuser.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Report', url: '/ReportActiveuser' },
              { title: 'ReportActiveuser', active: true }
            ],
            pageTitle: 'Report Activeuser',
            rule: 'editor'
          }
        },
        //===============================================================
        // End Report
        //===============================================================

        //===============================================================
        // START REVIEW
        //===============================================================
        {
          path: '/review',
          name: 'imgreview',
          component: () => import('./views/Review/Review.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Photo Review', active: true }
            ],
            pageTitle: 'รูปภาพรีวิว',
            rule: 'editor'
          }
        },
        {
          path: '/review/add',
          name: 'addimgreview',
          component: () => import('./views/Review/AddImgReview.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Review', url: '/review' },
              { title: 'Add Photo Review', active: true }
            ],
            pageTitle: 'เพิ่มรูปรีวิว',
            rule: 'editor'
          }
        },
        {
          path: '/review/edit/:id',
          name: 'editimgreview',
          component: () => import('./views/Review/EditImgReview.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Review', url: '/review' },
              { title: 'Edit Photo Review', active: true }
            ],
            pageTitle: 'แก้ไขรูปรีวิว',
            rule: 'editor'
          }
        },
        //===============================================================
        // END REVIEW
        //===============================================================

        //===============================================================
        // START BANKCOMPARE
        //===============================================================
        {
          path: '/bankcompare',
          name: 'bankcompare',
          component: () => import('./views/BankCompare/Bank.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Bank List', active: true }
            ],
            pageTitle: 'Bank Compare',
            rule: 'editor'
          }
        },
        {
          path: '/bankcompare/list',
          name: 'Listbankcompare',
          component: () => import('./views/BankCompare/list.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Bank List', url: '/bankcompare' },
              { title: 'List', active: true }
            ],
            pageTitle: 'Bank Compare',
            rule: 'editor'
          }
        },
        {
          path: '/bankcompare/listerror',
          name: 'Listerrorbankcompare',
          component: () => import('./views/BankCompare/listerror.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Bank List', url: '/bankcompare' },
              { title: 'Error List', active: true }
            ],
            pageTitle: 'Bank Compare',
            rule: 'editor'
          }
        },
        //===============================================================
        // END BANKCOMPARE
        //===============================================================
        {
          path: '/Profile',
          name: 'profile',
          component: () => import('./views/Profile/Profile.vue'),
          meta: {

            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Profile' },
              { title: 'Profile 2 Factor', active: true }
            ],
            pageTitle: 'Month',
            rule: 'editor',
            middleware: [auth]
          }
        },
        //===============================================================
        // START Jackpot
        //===============================================================
        {
          path: '/jackpot',
          name: 'imgjackpot',
          component: () => import('./views/Jackpot/Jackpot.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Photo jackpot', active: true }
            ],
            pageTitle: 'เพิ่มรูปแจ็คพอต',
            rule: 'editor'
          }
        },
        {
          path: '/jackpot/add',
          name: 'addimgjackpot',
          component: () => import('./views/Jackpot/AddImgJackpot.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Jackpots', url: '/jackpot' },
              { title: 'Add Photo Jackpot', active: true }
            ],
            pageTitle: 'เพิ่มรูปแจ็คพอต',
            rule: 'editor'
          }
        },
        //===============================================================
        // START CHECHBANKWITHDRAW
        //===============================================================
        {
          path: '/checkbankwithdraw/account',
          name: 'check-bankwithdraw-account',
          component: () => import('./views/CheckBankWithdraw/Bank.vue'),
          meta: {

            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Check BankWithdraw' },
              { title: 'BankWithdraw Account', active: true }
            ],
            pageTitle: 'BankWithdraw Account',
            rule: 'editor',
            middleware: [auth]
          }
        },
        // CHECK BANK WITHDRAW ADJUSTBALANCE
        {
          path: '/checkbankwithdraw/adjustbalance',
          name: 'check-bankwithdraw-adjustbalance',
          component: () => import('./views/CheckBankWithdraw/Adjustbalance.vue'),
          meta: {

            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Check BankWithdraw' },
              { title: 'BankWithdraw Adjustbalance', active: true }
            ],
            pageTitle: 'BankWithdraw Adjustbalance',
            rule: 'editor',
            middleware: [auth]
          }
        },
        // CHECK BANK WITHDRAW ADJUSTBALANCE
        {
          path: '/checkbankwithdraw/adjustwithdraw',
          name: 'check-bankwithdraw-adjustwithdraw',
          component: () => import('./views/CheckBankWithdraw/Adjustwithdraw.vue'),
          meta: {

            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Check BankWithdraw' },
              { title: 'BankWithdraw Adjustwithdraw', active: true }
            ],
            pageTitle: 'BankWithdraw Adjustwithdraw',
            rule: 'editor',
            middleware: [auth]
          }
        },
        // CHECK BANK WITHDRAW TRANSACTIONS
        {
          path: '/checkbankwithdraw/transactions/:bank/:id',
          name: 'check-bankwithdraw-transactions',
          component: () => import('./views/CheckBankWithdraw/Transaction.vue'),
          meta: {

            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Check BankWithdraw' },
              { title: 'BankWithdraw Transactions', active: true }
            ],
            pageTitle: 'BankWithdraw Transactions',
            rule: 'editor',
            middleware: [auth]
          }
        },
        // CHECK BANK WITHDRAW UPSLIP
        {
          path: '/checkbankwithdraw/upslip',
          name: 'check-bankwithdraw-upslip',
          component: () => import('./views/CheckBankWithdraw/Upslip.vue'),
          meta: {

            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Check BankWithdraw' },
              { title: 'BankWithdraw Upslip', active: true }
            ],
            pageTitle: 'BankWithdraw Upslip',
            rule: 'editor',
            middleware: [auth]
          }
        },
        //===============================================================
        // END CHECHBANKWITHDRAW

        //===============================================================
        // START TEMPORARY
        {
          path: '/SettingTemporary',
          name: 'settingtemporary',
          component: () => import('./views/Temporary/SettingTemporary.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Setting', url: '/SettingTemporary' },
              { title: 'Temporary Setting', active: true }
            ],
            pageTitle: 'Temporary Setting',
            rule: 'editor'
          }
        },
        {
          path: '/SettingTemporary/EditTemporary/:bank/:id',
          name: 'EditTemporary',
          component: () => import('./views/Temporary/EditTemporary.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Setting', url: '/SettingWalletDeposit' },
              { title: 'Deposit Setting', url: '/SettingWalletDeposit' },
              { title: 'Edit Deposit', active: true }
            ],
            pageTitle: 'แก้ไขข้อมูลบัญชีพัก',
            rule: 'editor'
          }
        },
        //===============================================================
        // END CHECHBANKWITHDRAW
        //===============================================================
        // Internal Transfer (Manual)
        {
          path: '/internal-transfer/manual',
          name: 'internal-transfer-manual',
          component: () => import('./views/InternalTransfer/manual-transfer.vue'),
          meta: {

            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Internal Transfer' },
              { title: 'Manual Transfer', active: true }
            ],
            pageTitle: 'โยกเงิน',
            rule: 'editor',
            middleware: [auth]
          }
        },
        {
          path: 'internal-transfer/logs',
          name: 'internal-transfer-logs',
          component: () => import('./views/InternalTransfer/logs.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Internal Transfer' },
              { title: 'Internal Transfer Logs', active: true }
            ],
            pageTitle: 'ประวัติการโยกเงิน',
            rule: 'editor'
          }
        },
        // Refund
        {
          path: '/refund/:transNo/:bank/:id',
          name: 'refund',
          component: () => import('./views/Refund/Refund.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Refund', active: true }
            ],
            pageTitle: 'Refund',
            rule: 'editor'
          }
        },
        // AGENT
        {
          pageTitle: 'Agent ตัวเกม',
          path: '/games',
          name: 'games',
          rule: 'editor',
          component: () => import('./views/Games/index.vue'),
          meta: {
            middleware: [auth]
          }
        }
      ],
      meta: {
        middleware: [auth]
      }
    },
    {
      path: '*',
      component: () => import('./views/Error/404.vue')
    }

  ]
})
import store from './store/store'

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }

  if (localStorage.userInfo) {
    const isAuthenticated = JSON.parse(localStorage.userInfo).isAuthenticated
    if (!isAuthenticated && to.path !== '/profile') {
      // User is not authenticated and trying to access a route other than /profile
      next('/profile') // Redirect to /profile
    } else {
      // User is authenticated or is navigating to /profile
      next() // Proceed to route
    }
  }

  const middleware = to.meta.middleware

  const context = {
    to,
    from,
    next,
    store
  }
  return middleware[0]({
    ...context
  })
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
